// import myaxios, {commonPrefix} from "@api";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        supportedLngs: ["en", "cn"],
        defaultNS: "_",
        fallbackNS: "_",
        debug: false,
        lng: "cn",
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        partialBundledLanguages: true,
        // saveMissing: true,
        //
        // backend: {
        //     request: (options, url, payload, callback) => {
        //         // Do nothing (prevents default request)
        //         callback(null, null); // Pass empty data to signal no default request
        //     },
        // },

        react: {
            useSuspense: !window.Cypress,
        },
    });

// i18n.on("initialized", (data) => {
//     console.log(data, lng);
// });

// i18n.on("missingKey", (lngs, namespace, key) => {
//     // Handle missing key here
//     const missingKeyData = {lngs, namespace, key}; // Data to send
//     myaxios.post(`${commonPrefix}/api/i18n/missingKey`, missingKeyData);
// });

export default i18n;
