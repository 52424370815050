import {Checkbox, Icon, LinearProgress, Table, TableBody, TableCell, TableContainer} from "@material-ui/core";
import {EmptyView} from "@ui";
import Pagination from "../components/Pagination";
import {useFilters} from "../hooks/filtersContext";
import type {columnType} from "../../../../types";
import TableHeadView from "../../../../utils/Page/Views/TableHeadView";
import type {ReactNode} from "react";
import {Fragment, useEffect, useState} from "react";
import {StyledTableCell, StyledTableRow} from "../../../../utils/utilComponents";
import clsx from "clsx";
import {isMobile} from "react-device-detect";
import classNames from "classnames";
import TableRow from "@material-ui/core/TableRow";
import {Translate} from "react-localize-redux";
import {useSelector} from "react-redux";
import {useSelections} from "ahooks";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {useTranslation} from "react-i18next";

type Props<TItem> = {
    disableSelectForItem?: (item: any) => boolean;
    disablePagination?: boolean;
    hideColumnIds?: string[];
    columns: columnType<TItem>[];
    itemProps?: (item: TItem) => Record<string, any>;
    selectActions?: (arg: {selected: string[]; clearAll: () => void}) => ReactNode;
};

export const FilteredTable = <TItem extends {id: string}>({
    disablePagination,
    disableSelectForItem,
    hideColumnIds,
    itemProps,
    columns,
    selectActions,
}: Props<TItem>) => {
    const listView = useSelector(({fuse}) => fuse.dialogs["listView"]);
    const {t} = useTranslation();

    const {swr, addFilter, filters} = useFilters();
    const {data, isLoading, mutate, isValidating} = swr;
    const content = data && "content" in data ? data.content : data;

    const [hides, setHides] = useState(new Set<string>());
    const [headerColumns, setHeaderColumns] = useState(columns?.filter((c) => !hides.has(c.id)));
    const [openRow, setOpenRow] = useState<string | null>(null);
    // const [checked, setChecked] = useState(new Set());

    const {selected, allSelected, toggleAll, isSelected, clearAll, toggle, partiallySelected} = useSelections<string>(
        content ? content.map((x) => x.id) : [],
        {
            defaultSelected: [],
        }
    );

    const page = data && "page" in data ? data.page : 1;
    const size = data && "size" in data ? data.size : content?.length || 0;

    useEffect(() => {
        setHeaderColumns(columns?.filter((c) => !hides.has(c.id)));
    }, [hides, columns]);
    useEffect(() => {
        if (hideColumnIds && hideColumnIds.length > 0) setHides(new Set(hideColumnIds));
    }, [hideColumnIds]);

    const forwardProps = {
        refresh: swr.mutate,
        setOpenRow,
        openRow,
    };

    const selectable = !!selectActions;

    const handleRequestSort = (property: string) => {
        if (filters.sord && filters.sord === "asc") {
            addFilter({sord: "desc", sidx: property});
            return;
        } else {
            addFilter({sord: "asc", sidx: property});
        }
    };

    return (
        <>
            {isValidating && !isLoading && <LinearProgress color={"secondary"} className={"h-4 absolute top-0 left-0 w-full"} />}

            {selectable && (
                <Card square className="bg-blue-lightest md:flex   items-center w-full py-8 px-12 ">
                    <Checkbox data-cy={"select-all"} checked={allSelected} indeterminate={partiallySelected} onChange={toggleAll} />

                    <Typography variant={"subtitle2"} className={"mx-12"}>
                        {t("selectedCount", {count: selected.length})}
                    </Typography>
                    {selectActions && selectActions({selected, clearAll})}
                </Card>
            )}
            <TableContainer>
                <Table size={listView ? "small" : "medium"}>
                    <TableHeadView<TItem>
                        hides={hides}
                        setHides={setHides}
                        columns={headerColumns}
                        allColumns={columns}
                        direction={filters.sord}
                        orderBy={filters.sidx}
                        onRequestSort={handleRequestSort}
                        smallHeader
                        selectable={selectable}
                    />

                    <TableBody>
                        {content && content?.length > 0 ? (
                            content.map((_, i) => {
                                const itemP = () => {
                                    if (itemProps) return itemProps(_);
                                    else {
                                        return {};
                                    }
                                };

                                // const isChecked = checked ? checked.has(_.id) : false;
                                return (
                                    <Fragment key={i}>
                                        <StyledTableRow
                                            tabIndex={-1}
                                            {...itemP()}
                                            className={clsx(
                                                isSelected(_.id) && "bg-blue-lightest",
                                                listView ? "h-24" : "h-64",
                                                "truncate",
                                                i < content.length - 1 && "border-b-1",
                                                " relative",
                                                itemProps && itemProps(_).className
                                            )}>
                                            {/*<StyledTableCell padding={"none"} className={"text-right md:hidden table-cell"} component="td" scope="row">*/}
                                            {/*    <IconButton*/}
                                            {/*        onClick={(e)=>{*/}
                                            {/*            e.stopPropagation();*/}
                                            {/*            this.setState(state => ({openRow: state.openRow === i ? null  : i}));*/}
                                            {/*        }}*/}
                                            {/*    ><Icon>{openRow === i ? "expand_less" : "expand_more"}</Icon></IconButton>*/}
                                            {/*</StyledTableCell>*/}

                                            {selectable && isMobile && (
                                                <StyledTableCell
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setOpenRow(openRow === _.id ? null : _.id);
                                                    }}
                                                    scope="row"
                                                    className={classNames("text-grey-dark")}
                                                    padding={"none"}>
                                                    <Icon
                                                        fontSize={"inherit"}
                                                        style={{
                                                            transform: openRow === _.id ? "rotate(0)" : "rotate(180deg)",
                                                        }}
                                                        className={"transition-all duration-300 text-24"}>
                                                        {"expand_less"}
                                                    </Icon>
                                                </StyledTableCell>
                                            )}

                                            <StyledTableCell
                                                onClick={(e) => {
                                                    if (!isMobile || selectable) return;
                                                    e.stopPropagation();
                                                    setOpenRow(openRow === _.id ? null : _.id);
                                                }}
                                                scope="row"
                                                className={classNames("w-24 md:w-40 text-grey-dark")}
                                                padding={"none"}>
                                                <div className={clsx(selectable ? "justify-start" : "justify-center", "flex items-center ")}>
                                                    {!selectable && (
                                                        <Icon
                                                            fontSize={"inherit"}
                                                            style={{
                                                                transform: openRow === _.id ? "rotate(0)" : "rotate(180deg)",
                                                            }}
                                                            className={"transition-all duration-300 text-24  md:hidden"}>
                                                            {"expand_less"}
                                                        </Icon>
                                                    )}
                                                    {selectable && (
                                                        <Checkbox
                                                            size={listView ? "small" : "medium"}
                                                            disabled={typeof disableSelectForItem === "function" && disableSelectForItem(_)}
                                                            data-cy={"select-item"}
                                                            className={"-ml-3"}
                                                            checked={isSelected(_.id)}
                                                            onClick={(event) => event.stopPropagation()}
                                                            onChange={() => toggle(_.id)}
                                                        />
                                                    )}
                                                    <span>{size ? size * (page - 1) + i + 1 : i + 1}</span>
                                                </div>
                                            </StyledTableCell>

                                            {
                                                //     ViewItem ? (
                                                //     <ViewItem {...forwardProps} index={i} key={i} item={_} />
                                                // ) : (
                                                columns?.map((cell, j) => {
                                                    const {padding, itemView, id, className, hideInMobile, customClassName, ...rest} = cell;
                                                    return (
                                                        !hides.has(id) && (
                                                            <StyledTableCell
                                                                key={j}
                                                                padding={padding}
                                                                className={clsx(
                                                                    className,
                                                                    customClassName?.(_),
                                                                    hideInMobile && "hidden md:table-cell"
                                                                )}
                                                                {...rest}>
                                                                {itemView?.(_, forwardProps)}
                                                            </StyledTableCell>
                                                        )
                                                    );
                                                })
                                                // )
                                            }
                                        </StyledTableRow>

                                        {openRow === _.id && (
                                            <TableRow className={"print:hidden "}>
                                                <StyledTableCell
                                                    // @ts-ignore
                                                    colSpan={"100%"}
                                                    padding={"none"}>
                                                    <Table size={"small"}>
                                                        <TableBody>
                                                            <>
                                                                {columns?.map((cell, j) => {
                                                                    const {itemView, id, className, label, customClassName, hideInMobile, ...rest} =
                                                                        cell;
                                                                    return (
                                                                        !hides.has(id) &&
                                                                        hideInMobile && (
                                                                            <StyledTableRow>
                                                                                <StyledTableCell>
                                                                                    <Translate id={label} />
                                                                                </StyledTableCell>
                                                                                <StyledTableCell
                                                                                    key={j}
                                                                                    className={clsx(className, customClassName?.(_))}
                                                                                    {...rest}>
                                                                                    {itemView?.(_, forwardProps)}
                                                                                </StyledTableCell>
                                                                            </StyledTableRow>
                                                                        )
                                                                    );
                                                                })}
                                                            </>
                                                        </TableBody>
                                                    </Table>
                                                </StyledTableCell>
                                            </TableRow>
                                        )}

                                        {/*{!!ViewItemMobile && openRow === i &&*/}
                                        {/*<Hidden mdUp>*/}
                                        {/*    <TableRow*/}
                                        {/*        className={classNames(`h-64 cursor-pointer md:hidden bg-grey-lighter`)}*/}
                                        {/*        hover*/}
                                        {/*        tabIndex={-1}*/}
                                        {/*    >*/}
                                        {/*        <TableCell colSpan={"100%"} padding={"none"} component="td" scope="row">*/}
                                        {/*            <ViewItemMobile*/}
                                        {/*                {...forwardProps}*/}
                                        {/*                open={openRow === i}*/}
                                        {/*                index={i}*/}
                                        {/*                key={i} item={_}/>*/}
                                        {/*        </TableCell>*/}
                                        {/*    </TableRow>*/}
                                        {/*</Hidden>*/}
                                        {/*}*/}
                                    </Fragment>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell
                                    align={"center"}
                                    // @ts-ignore
                                    colSpan={"100%"}>
                                    <EmptyView refresh={mutate} />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {content && content.length > 0 && !disablePagination && (
                <div className="flex items-center justify-end border-t" data-cy={"pagination-wrapper"}>
                    <Pagination />
                </div>
            )}
        </>
    );
};
