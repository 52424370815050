import {Checkbox, Icon, LinearProgress} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {EmptyView} from "@ui";
import clsx from "clsx";
import type {ReactNode} from "react";
import {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ErrorButton} from "../../../../utils/utilComponents";
import Pagination from "../components/Pagination";
import {useFilters} from "../hooks/filtersContext";
import {ViewportList} from "react-viewport-list";
import {useMainContext} from "../hooks/mainContext";

type SelectOptions<TItem> = {
    disableSelectForItem?: (item: any) => boolean;
    selectable?: boolean;
    disablePagination?: boolean;
    renderItem?: (item: TItem) => ReactNode;
    itemProps?: (item: TItem) => Record<string, any>;
    renderItemDrawer?: ({item, closeDrawer}: {item: TItem; closeDrawer: () => void}) => ReactNode;
};

// todo change the name to FilteredList
export const FilteredListView = <TItem extends {id: string}>({
    disablePagination,
    disableSelectForItem,
    selectable,
    itemProps,
    renderItem,
    renderItemDrawer,
}: SelectOptions<TItem>) => {
    const {t} = useTranslation();
    const viewportRef = useRef(null);
    const [checked, setChecked] = useState(new Set());
    const [selected, setSelected] = useState<TItem>();
    const {hasFilters, clearFilters, swr} = useFilters();
    const {miniFilters} = useMainContext();
    const {data, error, isLoading, mutate, isValidating} = swr;
    const content = data && "content" in data ? data.content : data;
    const page = data && "page" in data ? data.page : 1;
    const size = data && "size" in data ? data.size : content?.length || 0;
    const topRef = useRef<HTMLDivElement>(null);

    return (
        <>
            {isValidating && !isLoading && <LinearProgress color={"secondary"} className={"h-4 absolute top-0 left-0 w-full"} />}
            <div ref={topRef} className={"absolute top-0"} />
            <div className={"flex items-stretch"}>
                <List
                    dense
                    classes={{root: "py-0 flex-1 scroll-container overflow-y-scroll"}}
                    data-cy={"list-content"}
                    ref={viewportRef}
                    style={{maxHeight: `calc(100vh - ${miniFilters ? 255 : 210}px)`}}>
                    {content && content.length > 0 && !isLoading ? (
                        <ViewportList<TItem>
                            viewportRef={viewportRef}
                            scrollThreshold={30}
                            items={content}
                            initialPrerender={30}
                            overscan={30}
                            initialIndex={0}>
                            {(_, i) => {
                                const isChecked = checked ? checked.has(_.id) : false;
                                const itemP = () => {
                                    if (itemProps) return itemProps(_);
                                    else {
                                        return {};
                                    }
                                };
                                return (
                                    <ListItem
                                        component={"li"}
                                        divider
                                        classes={{
                                            root: clsx(
                                                "py-0 md:flex-1",
                                                selected ? " w-0 overflow-hidden md:w-auto" : "flex-1 flex-wrap md:flex-nowrap"
                                            ),
                                        }}
                                        key={i}
                                        selected={selected ? selected.id === _.id : false}
                                        button={itemProps ? itemProps(_).onClick : false}
                                        // divider={!listView}
                                        {...itemP()}
                                        onClick={() => {
                                            topRef?.current?.scrollIntoView?.();
                                            setSelected(_);
                                        }}
                                        className={clsx(
                                            ((itemProps && typeof itemProps(_).onClick === "function") || renderItemDrawer) && "cursor-pointer",
                                            itemProps && itemProps(_).className,
                                            "hover:bg-grey-lightest"
                                        )}>
                                        <Typography className={"-ml-16 pl-4 w-40 text-grey-dark"}>
                                            {size && page ? size * (page - 1) + i + 1 : i + 1}
                                        </Typography>

                                        {selectable && (
                                            <Checkbox
                                                disabled={typeof disableSelectForItem === "function" && disableSelectForItem(_)}
                                                classes={{root: "mr-8"}}
                                                checked={isChecked}
                                                onClick={(event) => event.stopPropagation()}
                                                onChange={() =>
                                                    checked.has(_.id)
                                                        ? setChecked((prev) => {
                                                              prev.delete(_.id);
                                                              return prev;
                                                          })
                                                        : setChecked((prev) => new Set(prev).add(_.id))
                                                }
                                            />
                                        )}
                                        {renderItem && renderItem(_)}
                                    </ListItem>
                                );
                            }}
                        </ViewportList>
                    ) : (
                        <EmptyView
                            refresh={mutate}
                            error={error}
                            loading={isLoading}
                            label={
                                hasFilters ? (
                                    <div>
                                        {t("No result for your search")}{" "}
                                        <ErrorButton startIcon={<Icon>close</Icon>} onClick={clearFilters} className={"ml-12 "} variant={"contained"}>
                                            {t("Clear search")}
                                        </ErrorButton>
                                    </div>
                                ) : undefined
                            }
                        />
                    )}
                </List>

                {selected && renderItemDrawer && (
                    <div className={"flex-1  border-l"}>
                        {renderItemDrawer({
                            item: selected,
                            closeDrawer: () => setSelected(undefined),
                        })}
                    </div>
                )}
            </div>
            {content && content.length > 0 && !disablePagination && (
                <div className="flex items-center justify-end border-t" data-cy={"pagination-wrapper"}>
                    <Pagination />
                </div>
            )}
        </>
    );
};
