import {Icon} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import useSWR from "swr";
import {utils, writeFile} from "xlsx";
import type {dataType} from "../../../../types";
import {useFilters} from "@page";
import {useMainContext} from "../hooks/mainContext";
import type {ExcelType} from "../types";

const ExcelBtn = <T,>({exportExcel, exportTitle}: ExcelType<T>) => {
    const {t} = useTranslation();
    const {link} = useMainContext();
    const {filters} = useFilters();
    const {data} = useSWR<dataType<T> | T[]>([link, filters]);

    const content = data && "content" in data ? data.content : data;

    const exportDataToExcel = () => {
        if (!exportExcel || !exportTitle || !content || content.length === 0) return;
        const d = exportExcel(content);
        // console.log(data);
        // d.unshift(exportTitle);
        // const data = [[1,2,3],[4,5,6]];
        /* convert state to workbook */
        // @ts-ignore
        const ws = utils.aoa_to_sheet(d, {origin: 1});
        const wb = utils.book_new();

        ws["!cols"] = [{wch: 4}, ...exportTitle.map(() => ({wch: 15}))];
        utils.sheet_add_aoa(ws, [exportTitle], {
            origin: 0,
        });
        utils.book_append_sheet(wb, ws, "SheetJS");

        /* generate XLSX file and send to client */
        writeFile(wb, `${link}-${moment(new Date()).format("L")}.xlsx`);
    };

    return (
        <div className={"mb-24"}>
            <Divider className={"my-12"} />
            <div className="px-12">
                <Button
                    disabled={!(content && content.length > 0)}
                    variant={"outlined"}
                    startIcon={<Icon>download</Icon>}
                    onClick={exportDataToExcel}>
                    {t("Export shown data")}
                </Button>
            </div>
        </div>
    );
};

export default ExcelBtn;
